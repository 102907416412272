import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cloned: HttpRequest<any> = request.clone({withCredentials: true});
    return next.handle(cloned)
  }
}

export const AuthInterceptorProviders = [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}];
