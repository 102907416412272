

export enum FreightBidStatusEnum {
  DEFAULT = "",
  ERROR = "error",
  SAVED = "saved",
  SUBMITTED = "submitted",
  BOOKING_REQUESTED = "booking-requested",
  BOOKED = "booked"

}
