import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {BidsComponent} from "./bids/bids.component";
import {BookingsComponent} from "./bookings/bookings.component";
import {QuoteComponent} from './quote/quote.component';
import {ThankYouComponent} from "./thank-you/thank-you.component";
import {ConfirmationComponent} from "./quote/confirmation/confirmation.component";
import {NotFoundComponent} from './shared/components/not-found/not-found..component';
import {InvalidLinkComponent} from './invalid-link/invalid-link.component';

const routes: Routes = [
  {path: 'bids', component: BidsComponent},
  {path: 'bookings', component: BookingsComponent},
  {path: 'quote/:id', component: QuoteComponent},
  {path: 'confirmation', component: ConfirmationComponent},
  {path: 'not-found', component: NotFoundComponent},
  {path: 'invalid', component: InvalidLinkComponent},
  {
    path: 'broker/:brokerId', // There can potentially be a broker component here
    children: [
      {
        path: 'freight-broker',
        children: [
          {
            path: ':freightBrokerId',
            component: BidsComponent
          },
          {
            path: ':freightBrokerId/book',
            component: BookingsComponent
          }
        ]
      }
    ]
  },
  {
    path: 'thank-you',
    component: ThankYouComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
