import {Component, Input, OnInit} from '@angular/core';
import {QuoteInfo} from "../../core/models/quote-info.interface";

@Component({
  selector: 'app-change-request-info',
  templateUrl: './change-request-info.component.html',
  styleUrls: ['./change-request-info.component.scss']
})
export class ChangeRequestInfoComponent implements OnInit {
  @Input() quoteInfo: QuoteInfo = {};

  ngOnInit(): void {
  }
}
