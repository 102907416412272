import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Freight} from "../models/freight.interface";
import {FreightBid} from "../models/freight-bid.interface";

@Injectable({
	providedIn: 'root'
})
export class FreightService {
	baseUrl: string = `${environment.url}/freight`;
	constructor(private http: HttpClient) {}

  findFreightByFreightBrokerId(freightBrokerId: number): Observable<Freight> {
    return this.http.get<Freight>(`${this.baseUrl}/freight-broker/${freightBrokerId}`);
  }

  saveFreightBid(bid: FreightBid): Observable<FreightBid>{
    return this.http.post<FreightBid>(`${this.baseUrl}/save-bid`, bid);
  }

  submitFreightBids(bids: FreightBid[]): Observable<FreightBid[]> {
    return this.http.post<FreightBid[]>(`${this.baseUrl}/submit-bids`, bids);
  }

  removeFreightBid(bidId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/bid/${bidId}`);
  }

  acceptBooking(booking: FormData, bidId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/book/${bidId}`, booking );
  }

  declineBidRequest(freightId: number, freightBrokerId: number): Observable<void> {
    return this.http.get<void>(`${this.baseUrl}/${freightId}/decline-bids/${freightBrokerId}`);
  }

  declineBooking(freightBid: FreightBid): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/decline-booking`, freightBid);
  }
}
