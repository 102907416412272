import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-pdf-container',
  templateUrl: './pdf-container.component.html',
  styleUrls: ['./pdf-container.component.scss']
})
export class PdfContainerComponent {
  @Input() pdfSrc: any;
  @Output() pdfRenderedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  zoom = 0.95
  isDragging: boolean = false;

  triggerConsole(): void {
    this.pdfRenderedEvent.emit(true);
  }

  downloadPdf(): void {
    window.open(this.pdfSrc);
  }

  increaseZoom(): void {
    this.zoom += 0.15
  }

  decreaseZoom(): void {
    this.zoom -= 0.15
  }

  onDrag(event: MouseEvent, pdfViewer: any): void {
    if (this.isDragging) {
      const x: number = pdfViewer.element.nativeElement.children[0].scrollLeft - event.movementX;
      const y: number = pdfViewer.element.nativeElement.children[0].scrollTop - event.movementY;
      pdfViewer.element.nativeElement.children[0].scrollTo(x, y);
    }
  }
  onDragStarted(): void {
    this.isDragging = true;
  }
  onDragEnded(): void {
    this.isDragging = false;
  }

}
