<div class="p-6 my-4 ">

  <div class="flex flex-col">
    <span class="text-2xl mb-4">Changes have been requested on quote #{{quoteInfo.quoteNo}}</span>
    <span class="text-lg ml-2"><strong>Who requested changes: </strong> {{quoteInfo.changeRequestEmail}}</span>
    <span class="text-lg ml-2"><strong>When: </strong> {{quoteInfo.changeRequestDate | date}}</span>
    <span class="text-lg ml-2"><strong>Reason: </strong> {{quoteInfo.changeRequestReason}}</span>
    <span class="text-lg ml-2"><strong>Description: </strong> {{quoteInfo.changeRequestDescription}}</span>
  </div>

</div>
