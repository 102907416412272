import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {QuoteService} from '../core/services/quote.service';
import {ApproveDialogComponent} from "./dialogs/approve-dialog/approve-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EstimateDateOption} from "../core/models/estimate-date-option.interface";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarActionEnum} from "../core/enums/snackbar-action.enum";
import {PdfViewerComponent} from "ng2-pdf-viewer";
import {ApprovalInfo} from '../core/models/approval-info.interface';
import {QuoteInfo} from "../core/models/quote-info.interface";
import {QuoteStatusEnum} from "../core/enums/quote-status.enum";
import {ErrorDialogComponent} from "./dialogs/error-dialog/error-dialog.component";
import {HttpStatusCode} from '@angular/common/http';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
  @ViewChild(PdfViewerComponent, { static: false }) pdfViewer: PdfViewerComponent | undefined;

  quoteId: number | undefined;
  quoteInfo: QuoteInfo | undefined;
  status: string = '';
  pdfSrc: any;
  fileArray: File[] = [];
  pdfRendered: boolean = false;

  dateOptions: EstimateDateOption[] = [];
  isLoading: boolean = true;
  changeRequestMode: boolean = false;

  approvalForm = this.fb.group({
    selectControl: new FormControl(''),
    installDate: [null],
    certified: [null, Validators.required],
    estimateDate: [null],
    po: [null, Validators.maxLength(35)],
    uploadPo: [null]
  }, { validator: this.requireInstallOrEstimatedDate });

  constructor(private route: ActivatedRoute,
              private router: Router,
              private quoteService: QuoteService,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private snackbar: MatSnackBar) {}

  ngOnInit(): void {
    this.route.params.subscribe({
      next: (params: Params): void => {
        this.quoteId = params['id'];
        if (this.quoteId) {
          this.quoteService.getQuoteInfo(this.quoteId).subscribe({
            next: (data: QuoteInfo): void => {
              this.quoteInfo = data;
              if (data.status) {
                this.status = data.status
              }
            },
            error: (err): void => {
              console.error(err);
              if (err?.status === HttpStatusCode.NotFound) {
                this.router.navigate(['/not-found']).then();
              }
              if (err?.status === HttpStatusCode.Unauthorized) {
                this.router.navigate(['/invalid']).then();
              }
            },
            complete: (): void => {
              if (this.quoteInfo?.status === QuoteStatusEnum.APPROVAL_REQUESTED ||
                  this.quoteInfo?.status === QuoteStatusEnum.APPROVED ||
                  this.quoteInfo?.status === QuoteStatusEnum.SALES_ORDER) {
                this.pdfSrc = this.quoteInfo.quotePdfUrl;
              }
              this.isLoading = false;
            }
          });
        }
      },
      error: (err): void => {
        console.error(err);
      }
    });

    this.dateOptions = this.quoteService.getDateRangeOptions();
  }

  requireInstallOrEstimatedDate(formGroup: FormGroup) {
    const installDate = formGroup.get('installDate')?.value;
    const estimateDate = formGroup.get('estimateDate')?.value;

    if (!installDate && !estimateDate) {
      return { requireInstallOrEstimateDate: true };
    }

    return null;
  }

  approveQuote(): void {
    if (!this.approvalForm.touched || !this.approvalForm.valid) {
      this.snackbar.open('Please fill out all required fields before submitting', SnackbarActionEnum.ALERT);
      this.approvalForm.markAsDirty();
      return;
    } else {
      const dialogRef: MatDialogRef<ApproveDialogComponent> = this.dialog.open(ApproveDialogComponent, {
        data: {
          dateOptions: this.dateOptions
        }
      });

      dialogRef.afterClosed().subscribe((result: boolean): void => {
        if (result && this.quoteId) {

          let installDate: string;
          let isEstimate: boolean;

          if (this.approvalForm.get('installDate')?.value) {
            const tempDate: Date = this.approvalForm.get('installDate')?.value;
            installDate = tempDate.toISOString().split('T')[0];
            isEstimate = false;
          }  else {
            if (JSON.stringify(this.approvalForm.get('estimateDate')?.value!) === '{}') {
              installDate = '';
            } else {
              installDate = new Date(this.approvalForm.get('estimateDate')?.value!).toISOString().substring(0, 10);
            }
            isEstimate = true;
          }

          const approvalInfo: ApprovalInfo = {
            name: this.quoteInfo?.approverName,
            email: this.quoteInfo?.approverEmail,
            installDate: installDate,
            isEstimate: isEstimate,
            po: this.approvalForm.get('po')?.value ?? '',
            certified: result,
            documentName: '',
            documentHash: ''
          };

          const formData: FormData = new FormData();

          this.fileArray.length ?
            formData.append('files', this.fileArray[0], this.fileArray[0].name) :
            formData.append('files', '');

          formData.append('body', JSON.stringify(approvalInfo));

          this.quoteService.submitApproval(this.quoteId, formData).subscribe({
            next: (response: QuoteInfo): void => {
              if (!response.conflictingUpdate) {
                this.snackbar.open('Successfully approved quote', SnackbarActionEnum.SUCCESS);
                this.status = QuoteStatusEnum.APPROVED;
                this.quoteInfo!.approverEmail = approvalInfo.email;
                this.quoteInfo!.approverName = approvalInfo.name;
                this.quoteInfo!.po = approvalInfo.po;
                this.quoteInfo!.approvedTs = new Date().toISOString().split('T')[0];
                this.quoteInfo!.installDate = installDate;
                this.quoteInfo!.isEstimate = isEstimate ? 'estimate' : 'install';
              } else {
                const errorDialogRef: MatDialogRef<ErrorDialogComponent> = this.dialog.open(ErrorDialogComponent, {
                  data: {
                    status: response.status,
                    quoteNo: response.quoteNo
                  }
                });

                errorDialogRef.afterClosed().subscribe({
                  next: () => window.location.reload()
                });
              }
            },
            error: (err): void => {
              console.error(err);
              this.snackbar.open('Failed to submit approval', SnackbarActionEnum.ERROR);
            }
          });
        }
      });
    }
  }

  downloadPdf(): void {
    window.open(this.pdfSrc);
  }

  fileArrayChanged($event: File[]) {
    this.fileArray = $event;
  }

  protected QuoteStatusEnum = QuoteStatusEnum;
  protected readonly caches = caches;
}
