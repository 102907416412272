import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {QuoteStatusEnum} from "../../../core/enums/quote-status.enum";

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
  status: string = '';
  quoteNo: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ErrorDialogComponent>) {
    this.status = data.status;
    this.quoteNo = data.quoteNo;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  protected readonly QuoteStatusEnum = QuoteStatusEnum;
}
