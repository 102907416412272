<div class="mt-4">

    <span class="font-bold"> {{title}} </span>

    <ngx-dropzone
      (change)="onFilesAdded($event)"
      [expandable]="true">
      <ngx-dropzone-label>
        <div class="bg-violet-300 p-2 circle">
          <mat-icon>upload_file</mat-icon>
        </div>
        <span class="text-violet-600 underline">Click to upload&nbsp;</span><span>or drag and drop.</span>
        <p class="text-gray-600 mt-2">PDF, CAD, or ZIP (max. 3MB)</p>
      </ngx-dropzone-label>
      <!--      File Previews-->
      <ngx-dropzone-preview
        *ngFor="let f of files"
        [file]="f"
        [removable]="true"
        (removed)="onRemove(f)"
        (click)="stopPropagation($event)"
        class="cursor-default border border-black">
        <ngx-dropzone-label class="text-black">
          {{ f.name }} ({{ f.type }})
          <span class="absolute bottom-0 right-1 italic text-red">New</span>
        </ngx-dropzone-label>
      </ngx-dropzone-preview>

    </ngx-dropzone>
</div>

