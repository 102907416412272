import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {Observable, Subject} from "rxjs";
import {Code} from "../models/code.interface";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  baseUrl: string = `${environment.url}/code`;
  codeSubject: Subject<Code[]> = new Subject();

  constructor(private http: HttpClient) {}

  findAll(): void {
    this.http.get<Code[]>(`${this.baseUrl}`).subscribe((response: Code[]) => {
      this.codeSubject.next(response);
    });
  }

  findPriceConfigCodes(): Observable<Code[]> {
    return this.http.get<Code[]>(`${this.baseUrl}/price-configs`);
  }

  findByType(codeType: string): Observable<Code[]> {
    return this.http.get<Code[]>(`${this.baseUrl}/${codeType}`);
  }

  findOne(id: number): Observable<Code> {
    return this.http.get<Code>(`${this.baseUrl}${id}`);
  }

  update(id: number, code: Partial<Code>): Observable<Code> {
    return this.http.patch<Code>(`${this.baseUrl}/${code.id}`, code);
  }

  create(code: Partial<Code>): Observable<Code> {
    return this.http.post<Code>(`${this.baseUrl}`, code);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
