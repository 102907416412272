import {Component, OnInit} from '@angular/core';
import {Shipment} from "../core/models/shipment.interface";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FreightService} from "../core/services/freight.service";
import {SnackbarActionEnum} from "../core/enums/snackbar-action.enum";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Freight} from "../core/models/freight.interface";
import {FreightBid} from "../core/models/freight-bid.interface";
import {FreightBidStatusEnum} from "../core/enums/freight-bid-status.enum";
import {Code} from "../core/models/shipping-code.model";
import {ShippingCodeService} from "../core/services/shipping-code.service";
import {HttpStatusCode} from '@angular/common/http';
import {ShipmentHandlingUnit} from "../core/models/shipment-handling-unit.interface";

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  shipments: Shipment[] = [new Shipment()];
  bookingForm: FormGroup = new FormGroup({});

  freightBrokerId: string = '';

  freightBid: FreightBid = {
    amount: 0
  };

  price: string = '';

  deliveryDate: string = '';
  pickupDate: string = '';
  deliveryTime: string = '';

  timeList: Code[] = [];
  timeZoneList: Code[] =[];

  fileArray: File[] = [];

  constructor(private route: ActivatedRoute, private freightService: FreightService,
              private fb: FormBuilder, private snackbar: MatSnackBar,
              private router: Router,
              private codeService: ShippingCodeService) {
  }
  ngOnInit() {
    this.timeList = this.codeService.getCodes('TIME');
    this.timeZoneList = this.codeService.getCodes('TIMEZONE');

    this.bookingForm = this.fb.group({
      puOrderNo:'',
      proNo:'',
      comment:''
    }, {validators: proOrPuValidator})

    if (this.route) {
      this.route.params.subscribe(async (params) => {
        this.freightBrokerId = params['freightBrokerId'];
        this.freightService
          .findFreightByFreightBrokerId(+this.freightBrokerId)
          .subscribe({
            next: (freight: any) => {
              this.setFreightBid(freight);
              this.shipments = freight.shipments;
              freight.shipments.forEach((shipment: Shipment) => {
                if (shipment.shipmentHandlingUnits) {
                  shipment.handlingUnits = {};
                  shipment.shipmentHandlingUnits.forEach((handlingUnit: ShipmentHandlingUnit) => {
                    if (handlingUnit.huType && handlingUnit.qty) {
                      if (!shipment.handlingUnits[handlingUnit.huType]) {
                        shipment.handlingUnits[handlingUnit.huType] = handlingUnit.qty;
                      } else {
                        shipment.handlingUnits[handlingUnit.huType] += handlingUnit.qty;
                      }
                    }
                  });
                }
              });
              this.setPickupDeliveryDate(freight.shipments);
            },
            error: (err): void => {
              console.error(err);
              if (err?.status === HttpStatusCode.NotFound) {
                this.router.navigate(['/not-found']);
              }
            },
          });
      });
    }
  }

  setFreightBid(freight: Freight){
    const freightBids = freight?.freightBrokers?.at(0)?.freightBids;
    if(freightBids){
      this.freightBid = freightBids
        .filter(freightBid => freightBid.bidStatus === FreightBidStatusEnum.BOOKING_REQUESTED)[0];
      if(!this.freightBid){
        this.router.navigate(['/thank-you']);
      }
      if(this.freightBid?.amount){
        this.price = this.formatAmount(this.freightBid.amount, this.freightBid.currency ? this.freightBid.currency : 'USD');
      }
      if(this.freightBid?.pickupDatetime){
        this.pickupDate = new Date(this.freightBid?.pickupDatetime).toLocaleDateString('en-us', {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric"
        });
      }
    }

  }

  formatAmount(amount: number, currency: string){
    const formatting_options = {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    }
    const dollarString = new Intl.NumberFormat("en-US", formatting_options);
    return dollarString.format(amount);
  }

  setPickupDeliveryDate(shipments: Shipment[]) {
    if (shipments && shipments[0]) {
      if(shipments[0].deliveryDate){
        this.deliveryDate = new Date(shipments[0].deliveryDate)
          .toLocaleDateString('en-us', {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric"
          });
      }
      const time = this.timeList.find((time) => time.value === shipments[0]?.deliveryTime);
      if(time && time.description) {
        this.deliveryTime = time.description;
      }
      const timeZone = this.timeZoneList.find((timeZone) => timeZone.value === shipments[0]?.deliveryTimeZone);
      if(timeZone && timeZone.description) {
        this.deliveryTime += ' ' + timeZone.description;
      }
      if(shipments[0].pickupDateTime) {
        this.deliveryDate = new Date(shipments[0].pickupDateTime)
          .toLocaleDateString('en-us', {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric"
          });

      }
    }
  }

  acceptBooking(){
    if(!this.bookingForm.valid) {
      this.snackbar.open('Please include a P/U Order # or a Pro #', '',{
        duration: 4000
      });
      return;
    } else {
      if (!this.fileArray.length) {
        this.snackbar.open('BOL is Required', '', {
          duration: 4000
        });
        return;
      }
    }
    if(this.freightBid.id){
      const formData: FormData = new FormData();

      this.fileArray.length ?
        formData.append('files', this.fileArray[0], this.fileArray[0].name) :
        formData.append('files', '');

      formData.append('body', JSON.stringify(this.bookingForm.value));
      this.freightService.acceptBooking(formData, +this.freightBid.id).subscribe({
        next: () => {
          this.router.navigate(['/thank-you']).then(() => {
            this.snackbar.open('Booking Accepted', SnackbarActionEnum.SUCCESS,{
              duration: 2000
            });
          });
        },
        error: () => {
          this.snackBookingError();
        }
      });
    } else {
      this.snackBookingError();
    }
  }

  snackBookingError(): void {
    this.snackbar.open('Error Accepting Booking', SnackbarActionEnum.ERROR,{
      duration: 2000
    });
  }

  declineBooking(): void {
    this.freightService.declineBooking(this.freightBid).subscribe({
      next: () => {
        this.router.navigate(['/thank-you']).then();
      },
      error: () => {
        this.snackbar.open('Error Declining', SnackbarActionEnum.ERROR,{
          duration: 2000
        });
      }
    });
  }

  fileArrayChanged($event: File[]) {
    this.fileArray = $event;
  }



}

export const proOrPuValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return !!(control.get('puOrderNo')?.value || control.get('proNo')?.value) ? null : {proOrPuInvalid: true};
};
