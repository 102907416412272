import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, NgForm} from "@angular/forms";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import {EstimateDateOption} from "../../core/models/estimate-date-option.interface";
import {Contact} from "../../core/models/contact.interface";
import {QuoteInfo} from "../../core/models/quote-info.interface";
import {QuoteStatusEnum} from "../../core/enums/quote-status.enum";

@Component({
  selector: 'app-quote-approval',
  templateUrl: './quote-approval.component.html',
  styleUrls: ['./quote-approval.component.scss']
})
export class QuoteApprovalComponent implements OnInit {
  @ViewChild('estimatedInstallCheckbox') estimatedInstallCheckbox!: MatCheckbox;
  @Input() dateOptions: EstimateDateOption[] = [];
  @Input() approvalForm: FormGroup = new FormGroup({});
  @Input() ngForm: NgForm | undefined;
  @Input() quoteInfo: QuoteInfo | undefined;
  @Output() fileChangeEmitter: EventEmitter<File[]> = new EventEmitter<File[]>();

  contacts: Contact[] = [];
  todayDate:Date = new Date();
  existingEstimate: boolean = false;
  showEstimateMessage: boolean = false;

  ngOnInit(): void {
    if (this.quoteInfo && this.quoteInfo.contacts) {
      this.contacts = this.quoteInfo.contacts;
    }
  }

  compareDates(date1: Date, date2: string): boolean {
    if((JSON.stringify(date2) === '{}')) {
      return !date1.getTime;
    }

    if(date2 === '') {
      return !date1.getTime;
    }
    if (!date1.getTime) {
      return null === date2;
    }
    const parsedDate = new Date(date2);
    return new Date(date1).toISOString().substring(0, 10) === parsedDate.toISOString().substring(0, 10);
  }

  updateEstimateCheck(change: MatCheckboxChange): void {
    this.approvalForm.controls['installDate'].setValue('');

    this.existingEstimate = change.checked;

    if (this.existingEstimate) {
      this.approvalForm.controls['estimateDate'].setValue({});
    }
    this.showEstimateMessage = !this.showEstimateMessage;
  }

  protected readonly QuoteStatusEnum = QuoteStatusEnum;
}
