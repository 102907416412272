<mat-card [formGroup]="approvalForm" class="flex flex-col p-6 h-full bg-gray-200">
  <mat-card-title> Quote Approval Details </mat-card-title>

  <mat-checkbox formControlName="certified" class="mt-4">
    <span *ngIf="ngForm?.submitted && this.approvalForm.controls['certified'].hasError('required')" class="text-red">*By checking this box certify that I am authorized to approve this quote</span>
    <span *ngIf="!ngForm?.submitted || !this.approvalForm.controls['certified'].hasError('required')">*By checking this box certify that I am authorized to approve this quote</span>
  </mat-checkbox>
  <div class="flex align-middle mb-2">
    <mat-icon class="text-yellow mt-1">warning</mat-icon>
    <span class="ml-2 mt-2">Please allow a 5-6 week installation lead time from date of PO approval.</span>
  </div>
  <div>
    <ng-container *ngIf="!existingEstimate">
      <mat-form-field class="w-1/2 mb-2" appearance="outline" subscriptSizing="dynamic">
        <mat-label>*Requested Install Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="installDate" [min]="todayDate"/>

        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="approvalForm.get('installDate')?.invalid">Cannot select a date prior to today.</mat-error>
        <mat-error *ngIf="ngForm?.submitted && approvalForm.hasError('requireInstallOrEstimateDate')"> Install Date is required </mat-error>
      </mat-form-field>
    </ng-container>


    <ng-container *ngIf="showEstimateMessage">
      <div class="flex justify-center">
        <span class="mb-2">Your Account Manager will contact you to hone in on the actual date.</span>
      </div>
    </ng-container>

    <ng-container *ngIf="existingEstimate">
      <mat-form-field class="w-1/2 mb-2" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Estimated Date</mat-label>
        <mat-select [compareWith]="compareDates" formControlName="estimateDate">
          <mat-option *ngFor="let option of dateOptions" [value]="option.date">{{ option.description }}</mat-option>
        </mat-select>
        <mat-error *ngIf="ngForm?.submitted && approvalForm?.hasError('requireInstallOrEstimateDate')"> Estimated Date is required </mat-error>
      </mat-form-field>
    </ng-container>
    <mat-checkbox
      class="w-1/2 m-auto"
      [checked]="existingEstimate"
      (change)="updateEstimateCheck($event)"
      color="primary"
      #estimatedInstallCheckbox>Estimated Install</mat-checkbox>
  </div>

  <div *ngIf="ngForm?.submitted && approvalForm?.hasError('requireInstallOrEstimateDate')">
    <span class="text-red ml-4">Please select a requested install date or an estimated install date.</span>
  </div>



  <mat-form-field appearance="outline" class="mt-2" subscriptSizing="dynamic">
    <mat-label> PO# </mat-label>
    <input type="text" matInput formControlName="po">
    <mat-error *ngIf="this.approvalForm.get('po')?.invalid">{{this.approvalForm.get('po')?.value.length}} / 35 Max Characters Allowed</mat-error>
  </mat-form-field>

  <app-file-upload
    (fileChangeEmitter)="this.fileChangeEmitter.emit($event)" title="PO File Upload">
  </app-file-upload>

</mat-card>


