<div class="h-max bg-gray-100 p-5">
  <div class="flex flex-row gap-5 mt-5">
    <div class="flex flex-col flex-1 gap-5">
      <form [formGroup]="freightBrokerForm">
        <mat-accordion>
          <ng-container *ngFor="let bid of bids.controls; index as i">
            <mat-expansion-panel [formGroup]="getFormGroup(bid)" [expanded]="i === expandedIndex">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{bid.value.freightBidNo}}
                </mat-panel-title>
                <mat-panel-description>
                  {{getBidStatus(bid)}}
                  <div class="flex w-full justify-end">
                    <section *ngIf="canModify(bid.value)">
                      <div class="table-cell">
                        <div class="flex">
                          <button mat-icon-button matTooltip="Save bid for later" (click)="saveBid(bid, $event)">
                            <mat-icon>save</mat-icon>
                          </button>
                          <button mat-icon-button matTooltip="Delete bid" (click)="removeBid(bid, i, $event)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </div>
                    </section>

                  </div>
                </mat-panel-description>

              </mat-expansion-panel-header>
              <div class="flex flex-row ">
                <mat-form-field [floatLabel]="!canModify(bid.value) ? 'always' : 'auto'"  matTooltip="This is your reference # to the bid." class="w-1/3">
                  <mat-label>Quote #</mat-label>
                  <input matInput formControlName="freightBidNo" [disabled]="!canModify(bid.value)"
                         [readonly]="!canModify(bid.value)" type="text"/>
                  <mat-error>Quote # <strong>required</strong>.</mat-error>
                </mat-form-field>

              </div>
              <div class="flex flex-row  justify-between">
                <div class="flex">
                  <mat-form-field [floatLabel]="!canModify(bid.value) ? 'always' : 'auto'"  class="w-2/3">
                    <mat-label>Carrier Name</mat-label>
                    <input matInput formControlName="carrierName" [disabled]="!canModify(bid.value)"
                           [readonly]="!canModify(bid.value)" type="text"/>
                    <mat-error>Carrier Name <strong>required</strong>.</mat-error>
                  </mat-form-field>
                  <mat-form-field [floatLabel]="!canModify(bid.value) ? 'always' : 'auto'"  class="w-1/3">
                    <mat-label>Carrier SCAC</mat-label>
                    <input matInput formControlName="carrierScac" [disabled]="!canModify(bid.value)"
                           [readonly]="!canModify(bid.value)" type="text"/>
                    <mat-error>Carrier SCAC <strong>required</strong>.</mat-error>
                  </mat-form-field>
                </div>
                <mat-form-field [floatLabel]="!canModify(bid.value) ? 'always' : 'auto'"  appearance="outline" class="w-1/3">
                  <mat-label class="font-medium">Pickup Date</mat-label>
                  <input matInput [min]="minDate" [max]="deliveryDate" [matDatepicker]="datepicker" formControlName="pickupDatetime"
                         [matDatepickerFilter]="dateFilter"
                         [disabled]="!canModify(bid.value)" [readonly]="!canModify(bid.value)"/>
                  <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                  <mat-datepicker [disabled]="!canModify(bid.value)" #datepicker>
                  </mat-datepicker>
                  <mat-error>Date <strong>required</strong>.</mat-error>
                </mat-form-field>
              </div>
              <div class="flex flex-row justify-between">
                <div class="flex">
                  <mat-form-field [floatLabel]="!canModify(bid.value) ? 'always' : 'auto'"  class="w-2/3">
                    <mat-label>Broker Name</mat-label>
                    <input matInput formControlName="brokerName" [disabled]="!canModify(bid.value)"
                           [readonly]="!canModify(bid.value)" type="text"/>
                    <mat-error>Broker Name <strong>required</strong>.</mat-error>
                  </mat-form-field>
                  <mat-form-field [floatLabel]="!canModify(bid.value) ? 'always' : 'auto'"  class="w-1/3">
                    <mat-label>Broker SCAC</mat-label>
                    <input matInput formControlName="brokerScac" [disabled]="!canModify(bid.value)"
                           [readonly]="!canModify(bid.value)" type="text"/>
                    <mat-error>Broker SCAC <strong>required</strong>.</mat-error>
                  </mat-form-field>
                </div>
                <div class="flex w-1/3">
                  <mat-form-field [floatLabel]="!canModify(bid.value) ? 'always' : 'auto'" >
                    <mat-label>Transit Time</mat-label>
                    <input matInput formControlName="transitTime" [disabled]="!canModify(bid.value)"
                           [readonly]="!canModify(bid.value)" type="text"/>
                    <mat-error>Transit Time <strong>required</strong>.</mat-error>
                  </mat-form-field>
                  <div class="my-auto mx-4 pb-4">Day(s)</div>
                </div>
              </div>
              <div class="flex flex-row gap-4 justify-center pb-4">
                <div class="flex flex-col w-1/4">
                  <mat-label>Equipment Type:</mat-label>
                  <mat-button-toggle-group formControlName="equipmentType" [disabled]="!canModify(bid.value)"
                                           name="equipmentType">
                    <mat-button-toggle matTooltip="Full Truckload" [disabled]="!canModify(bid.value)" value="ftl">FTL
                    </mat-button-toggle>
                    <mat-button-toggle matTooltip="Less than Truckload" [disabled]="!canModify(bid.value)" value="ltl">
                      LTL
                    </mat-button-toggle>
                    <mat-button-toggle matTooltip="Box Truck" [disabled]="!canModify(bid.value)" value="box">
                      BOX
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
                <div class="flex flex-col">
                  <mat-label>Service Type:</mat-label>
                  <mat-button-toggle-group formControlName="serviceType" [disabled]="!canModify(bid.value)"
                                           name="serviceType">
                    <mat-button-toggle [disabled]="!canModify(bid.value)" value="standard">Standard</mat-button-toggle>
                    <mat-button-toggle [disabled]="!canModify(bid.value)" value="expedited">Expedited
                    </mat-button-toggle>
                    <mat-button-toggle [disabled]="!canModify(bid.value)" value="guaranteed">Guaranteed
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
                <div class="flex flex-col  w-1/4">
                  <mat-label>Direct/Indirect Service:</mat-label>
                  <mat-button-toggle-group formControlName="indirectService" [disabled]="!canModify(bid.value)"
                                           name="indirectService">
                    <mat-button-toggle [disabled]="!canModify(bid.value)" [value]="false">Direct</mat-button-toggle>
                    <mat-button-toggle [disabled]="!canModify(bid.value)" [value]="true">Indirect</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
              <div class="flex flex-row">
                <mat-form-field [floatLabel]="!canModify(bid.value) ? 'always' : 'auto'" class="w-full h-24">
                  <mat-label>Comments</mat-label>
                  <textarea class="comments" matInput formControlName="comments" [disabled]="!canModify(bid.value)"
                            [readonly]="!canModify(bid.value)" type="text"></textarea>
                  <mat-error>Comments<strong>required</strong>.</mat-error>
                </mat-form-field>
              </div>
              <div class="flex flex-row justify-center">
                <mat-form-field [floatLabel]="!canModify(bid.value) ? 'always' : 'auto'" >
                  <mat-label>All In Price</mat-label>
                  <mat-select formControlName="currency" >
                    <mat-option [disabled]="!canModify(bid.value)"  value="USD">USD</mat-option>
                    <mat-option [disabled]="!canModify(bid.value)"  value="CAD">CAD</mat-option>
                    <mat-option [disabled]="!canModify(bid.value)"  value="MXN">MXN</mat-option>
                  </mat-select>
                  <mat-error>All In Price <strong>required</strong>.</mat-error>
                </mat-form-field>

                <mat-form-field [floatLabel]="!canModify(bid.value) ? 'always' : 'auto'" >
                  <mat-label>Amount</mat-label>
                  <input matInput formControlName="amount" [disabled]="!canModify(bid.value)"
                         [readonly]="!canModify(bid.value)" type="number"/>
                  <mat-error>Amount <strong>required</strong>.</mat-error>
                </mat-form-field>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
        <div class="flex flex-row justify-start">
          <button mat-button matTooltip="Create another bid" class="bg-primary-500 mt-2 bid-button"
                  (click)=addBid()>
            <mat-icon class="add-bid-icon">add_circle</mat-icon>
            Add a new Bid
          </button>
        </div>
      </form>
    </div>
    <div class="flex flex-col flex-1 gap-5">
      <mat-card class="bg-light p-5 h-full summary-submit">
        <div class="flex flex-row gap-5">
          <div class="flex flex-col flex-1">
            <div class="text-base">Delivery Date:</div>
            <div class="font-medium text-base">{{deliveryDateFormatted}}</div>
            <div class="font-medium text-base">{{deliveryTime}}</div>
          </div>
          <div class="flex flex-col flex-1">
            <div class="text-base">Destination</div>
            <div class="font-medium text-lg">{{shipments[0]['destinationName']}}</div>
            <div class="font-medium text-lg">{{shipments[0]['destinationAddress1']}}</div>
            <div *ngIf="shipments[0]['destinationAddress2']" class="font-medium text-lg">{{shipments[0]['destinationAddress2']}}</div>
            <div class="font-medium text-lg">{{shipments[0]['destinationCity']}}
              , {{shipments[0]['destinationState']}} {{shipments[0]['destinationPostal']}}</div>
          </div>
          <div class="flex flex-col flex-1">
            <div class="text-base">Contact:</div>
            <div class="font-medium text-base">{{shipments[0]['destinationContactName']}}</div>
            <div class="font-medium text-base">{{shipments[0]['destinationContactPhone']}} {{shipments[0]['destinationContactPhoneExt']}}</div>
            <div class="font-medium text-base">{{shipments[0]['destinationContactEmail']}}</div>
          </div>
        </div>
        <div class="flex flex-col flex-1 gap-5">
          <h1>Shipments</h1>
          <mat-accordion multi class="w-full">
            <ng-container *ngFor="let shipment of shipments; index as i">
              <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span>{{shipment.originName}}</span>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="flex flex-col gap-5">
                  <div class="flex flex-row gap-5">
                    <div class="flex flex-col flex-1">
                      <div>Origin</div>
                      <div class="font-medium text-base">{{shipment.originName}}</div>
                      <div class="font-medium text-base">{{shipment.originAddress1}}</div>
                      <div *ngIf="shipment.originAddress2" class="font-medium text-base">{{shipment.originAddress2}}</div>
                      <div class="font-medium text-base">{{shipment.originCity}}
                        , {{shipment.originState}} {{shipment.originPostal}}</div>
                      <div class="mt-2">Contact</div>
                      <div class="font-medium text-base">{{shipment.originContactName}}</div>
                      <div class="font-medium text-base">{{shipment.originContactPhone}} {{shipment.originContactPhoneExt}}</div>
                      <div class="font-medium text-base">{{shipment.originContactEmail}}</div>
                    </div>
                    <div class="flex flex-col flex-1">
                      <div>Special Services</div>
                      <ng-container *ngFor="let accessorial of shipment.shipmentAccessorials; index as i">
                        <div class="font-medium text-base">{{accessorial.name}}</div>
                      </ng-container>
                    </div>
                    <div class="flex flex-col w-2/5">
                      <div class="flex flex-row items-center gap-1">
                        <div>Skid Slots:</div>
                        <div class="font-medium">{{shipment.totalSkids}}</div>
                      </div>
                      <div class="flex flex-row items-center gap-1" *ngFor="let handlingUnit of shipment.handlingUnits | keyvalue">
                        <div class="capitalize">{{ handlingUnit.key.toLowerCase() }}s:</div>
                        <div class="font-medium">{{handlingUnit.value}}</div>
                      </div>
                      <div class="flex flex-row items-center gap-1">
                        <div>Linear Dimension:</div>
                        <div class="font-medium">{{shipment.totalLinearDimension | number}} {{shipment.dUnit}}</div>

                      </div>
                      <div class="flex flex-row items-center gap-1">
                        <div>Weight:</div>
                        <div class="font-medium">{{shipment.totalWeight | number}} {{shipment.wUnit}}</div>
                      </div>
                      <div>Handling Units:</div>
                      <ng-container *ngFor="let handlingUnit of shipment.shipmentHandlingUnits; index as i">
                        <div class="font-medium">
                          {{handlingUnit.qty}}x - {{handlingUnit.huType}}:
                          {{handlingUnit.length}}x{{handlingUnit.width}}x{{handlingUnit.height}}
                          {{handlingUnit.dUnit}}, {{handlingUnit.weight}} {{handlingUnit.wUnit}}
                        </div>
                      </ng-container>
                      <div class="mt-2">Instructions:</div>
                      <div class="font-medium">{{shipment.instructions}}</div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </div>
        <div class="flex flex-row justify-center p-2 gap-4">
          <button mat-raised-button class="submit-bids" [disabled]="bids.length === 0" (click)="submitBids()">Submit
            Bids
          </button>
          <button mat-raised-button class="decline-request" (click)="declineBidRequest()">Decline Request</button>
        </div>
      </mat-card>
    </div>
  </div>
</div>













