import {Component, OnInit} from '@angular/core';
import {Shipment} from "../core/models/shipment.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {FreightService} from "../core/services/freight.service";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Freight} from "../core/models/freight.interface";
import {FreightBid} from "../core/models/freight-bid.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarActionEnum} from "../core/enums/snackbar-action.enum";
import {EquipmentTypeEnum} from "../core/enums/equipment-type.enum";
import {FreightBidStatusEnum} from "../core/enums/freight-bid-status.enum";
import {Code} from "../core/models/shipping-code.model";
import {ShippingCodeService} from "../core/services/shipping-code.service";
import {addDays, isWeekend} from 'date-fns';
import {HttpStatusCode} from '@angular/common/http';
import {ShipmentHandlingUnit} from "../core/models/shipment-handling-unit.interface";

@Component({
  selector: 'app-bids',
  templateUrl: './bids.component.html',
  styleUrls: ['./bids.component.scss']
})
export class BidsComponent implements OnInit {

  shipments: Shipment[] = [new Shipment()];
  freightId: string = '';
  freightBrokerId: string = '';

  minDate = addDays(new Date(), 1);

  expandedIndex: number = 0;

  deliveryDate: Date | undefined = undefined;
  deliveryDateFormatted: string = '';
  deliveryTime: string = '';

  timeList: Code[] = [];
  timeZoneList: Code[] =[];

  brokerName = '';
  brokerScac = '';
  defaultQuote = '';
  quoteSequence = 1;

  freightBrokerForm: FormGroup = new FormGroup({
    bids: new FormArray([])
  });


  constructor(private route: ActivatedRoute, private freightService: FreightService,
              private fb: FormBuilder, private snackbar: MatSnackBar,
              private router: Router,
              private codeService: ShippingCodeService) {
  }


  getFormGroup(item: AbstractControl<FreightBid>): FormGroup {
    return item as FormGroup;
  }


  submitBids() {
    const validIndices: number[] = [];
    const validBids = this.bids.controls.filter((bid, index) => {
      const valid = bid.valid && bid.value.bidStatus != FreightBidStatusEnum.SUBMITTED;
      if (valid) {
        validIndices.push(index);
      }
      return valid;
    }).map(bid => bid.value);
    if (validBids.length < 1) {
      this.snackbar.open('Please fill out required fields. Note, there must be a Carrier Name and Scac or a Broker Name and Scac', '', {
        duration: 6000,
        verticalPosition: 'top'
      });
      return;

    }
    this.freightService.submitFreightBids(validBids).subscribe({
      next: (updatedBids) => {

        validIndices.forEach(index => this.bids.removeAt(index));
        updatedBids.forEach(updatedBid => this.bids.insert(0, this.bidToFormGroup(updatedBid)));
        this.snackbar.open('Bid Submitted', SnackbarActionEnum.SUCCESS, {
          duration: 2000
        });
      },
      error: () => {
        this.snackbar.open('Error Submitting Bid', SnackbarActionEnum.ERROR, {
          duration: 2000
        });
      }
    });
  }

  getBidStatus(bid: AbstractControl<FreightBid>) {
    return bid.value.bidStatus ? bid.value.bidStatus.toUpperCase() : null;
  }

  removeBid(bid: AbstractControl<FreightBid>, index: number, event: MouseEvent) {
    event.stopPropagation();
    let id = bid.value.id;
    if (id) {
      this.freightService.removeFreightBid(id).subscribe({
        next: () => {
          this.bids.removeAt(index);
          this.snackbar.open('Bid Removed', SnackbarActionEnum.SUCCESS, {
            duration: 2000
          });
        },
        error: () => {
          this.snackbar.open('Error Removing Bid', SnackbarActionEnum.ERROR, {
            duration: 2000
          });
        }
      });
    } else {
      this.bids.removeAt(index);
    }
  }

  saveBid(bid: AbstractControl<FreightBid>, event: MouseEvent) {
    event.stopPropagation();
    if(bid.value.pickupDatetime) {
      // @ts-ignore
      bid.value.pickupDatetime = bid.value.pickupDatetime.toISOString().substring(0, 10);
    }
    this.freightService.saveFreightBid(bid.value).subscribe({
      next: (freightBid: FreightBid) => {
        bid.value.id = freightBid.id;
        bid.value.bidStatus = freightBid.bidStatus;
        this.snackbar.open('Bid Saved', SnackbarActionEnum.SUCCESS, {
          duration: 2000
        });
      },
      error: () => {
        this.snackbar.open('Error Saving Bid', SnackbarActionEnum.ERROR, {
          duration: 2000
        });
      }
    });
  }

  ngOnInit() {
    this.timeList = this.codeService.getCodes('TIME');
    this.timeZoneList = this.codeService.getCodes('TIMEZONE');

    if (this.route) {
      this.route.params.subscribe(async (params) => {
        this.freightBrokerId = params['freightBrokerId'];
        this.loadInitialFreight();
      });
    }
  }

  loadInitialFreight(): void {
    this.freightService
      .findFreightByFreightBrokerId(+this.freightBrokerId)
      .subscribe({ next: (freight: any) => {
        this.brokerName = freight.broker.name;
        this.brokerScac = freight.broker.scac;
        this.defaultQuote = 'MMI-' + freight.shipments[0].shipmentOrderNo + '-' + freight.broker.id + '-';
        this.bids.clear();
        this.freightId = freight.id;
        this.shipments = freight.shipments;
        this.setDeliveryDate(freight.shipments);
        this.generateBrokerForm(freight);
        freight.shipments.forEach((shipment: Shipment) => {
          if (shipment.shipmentHandlingUnits) {
            shipment.handlingUnits = {};
            shipment.shipmentHandlingUnits.forEach((handlingUnit: ShipmentHandlingUnit) => {
              if (handlingUnit.huType && handlingUnit.qty) {
                if (!shipment.handlingUnits[handlingUnit.huType]) {
                  shipment.handlingUnits[handlingUnit.huType] = handlingUnit.qty;
                } else {
                  shipment.handlingUnits[handlingUnit.huType] += handlingUnit.qty;
                }
              }
            });
          }
          console.log(shipment.handlingUnits)
        });
      }, error: (err): void => {
          console.error(err);
          if (err?.status === HttpStatusCode.NotFound) {
            this.router.navigate(['/not-found']);
          }
        },
      });
  }

  setDeliveryDate(shipments: Shipment[]) {
    if (shipments && shipments[0] && shipments[0].deliveryDate) {
      this.deliveryDate = new Date(shipments[0].deliveryDate);
      this.deliveryDateFormatted = this.deliveryDate
        .toLocaleDateString('en-us', {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric"
        });
      const time = this.timeList.find((time) => time.value === shipments[0]?.deliveryTime);
      if(time && time.description) {
        this.deliveryTime = time.description;
      }
      const timeZone = this.timeZoneList.find((timeZone) => timeZone.value === shipments[0]?.deliveryTimeZone);
      if(timeZone && timeZone.description) {
        this.deliveryTime += ' ' + timeZone.description;
      }
    }
  }

  addBid() {
    let pickUpDate = addDays(new Date(), 1);
    while(isWeekend(pickUpDate)) {
      pickUpDate = addDays(pickUpDate, 1);
    }
    const newBid: FreightBid = {
      deliveryDatetime: this.deliveryDate,
      pickupDatetime: pickUpDate,
      serviceType: 'standard',
      indirectService: false,
      currency: 'USD',
      equipmentType: EquipmentTypeEnum.FTL,
      freightBrokerId: +this.freightBrokerId,
      brokerName: this.brokerName,
      brokerScac: this.brokerScac,
      freightBidNo: this.defaultQuote + this.quoteSequence
    }
    this.quoteSequence++;
    this.bids.push(this.bidToFormGroup(newBid));
    this.expandedIndex = this.bids.length - 1;
  }

  generateBrokerForm(freight: Freight) {
    if (freight?.freightBrokers?.[0]?.freightBids && freight.freightBrokers[0].freightBids.length > 0) {
      freight.freightBrokers[0].freightBids.forEach((bid: FreightBid) => {
        this.bids.push(this.bidToFormGroup(bid));
        this.quoteSequence++;
      })
    } else {
      // There are no previous bids, add a bid as a starting place for the broker/carrier
      this.addBid();
    }
  }

  bidToFormGroup(bid: FreightBid): FormGroup {
    return this.fb.group({
      id: bid.id,
      bidStatus: bid.bidStatus,
      freightBidNo: new FormControl(bid.freightBidNo, Validators.required),
      carrierName: bid.carrierName,
      carrierScac: bid.carrierScac,
      brokerName: bid.brokerName,
      brokerScac: bid.brokerScac,
      deliveryDatetime: bid.deliveryDatetime,
      deliveryTimezone: bid.deliveryTimezone,
      pickupDatetime: new FormControl(bid.pickupDatetime, Validators.required),
      transitTime: new FormControl(bid.transitTime, Validators.required),
      serviceType: new FormControl(bid.serviceType, Validators.required),
      equipmentType: new FormControl(bid.equipmentType, Validators.required),
      indirectService: new FormControl(bid.indirectService, Validators.required),
      comments: bid.comments,
      currency: new FormControl(bid.currency, Validators.required),
      amount: new FormControl(bid.amount, Validators.required),
      freightBrokerId: bid.freightBrokerId
    }, {validators: brokerCarrierValidator})
  }

  get bids(): FormArray {
    return this.freightBrokerForm.get('bids') as FormArray;
  }

  declineBidRequest(): void {
    this.freightService.declineBidRequest(+this.freightId, +this.freightBrokerId).subscribe({
      next: () => {
        this.router.navigate(['/thank-you']);
      },
      error: () => {
        this.snackbar.open('Error Declining', SnackbarActionEnum.ERROR, {
          duration: 2000
        });
      }
    });
  }

  canModify(bid: FreightBid): boolean {
    return bid.bidStatus != FreightBidStatusEnum.SUBMITTED;
  }

  dateFilter = (d: Date | null | undefined): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

}

export const brokerCarrierValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return !!(control.get('carrierName')?.value && control.get('carrierScac')?.value) ||
  !!(control.get('brokerName')?.value && control.get('brokerScac')?.value) ?
    null : {brokerCarrierInvalid: true};
};
