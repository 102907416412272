<mat-card class="p-4">
  <div class="flex justify-between mb-2">
    <mat-card-title>Alert</mat-card-title>
    <mat-icon (click)="closeDialog()" class="hover:cursor-pointer">close</mat-icon>
  </div>

  <div class="flex flex-col mb-4">
    <span class="text-xl" *ngIf="status === QuoteStatusEnum.CHANGE_REQUESTED">Changes we're requested on quote number {{quoteNo}}.</span>
    <span class="text-xl" *ngIf="status === QuoteStatusEnum.APPROVED">Quote number {{quoteNo}} has been approved!</span>
    <span>Please exit this dialog to view the {{ status === QuoteStatusEnum.CHANGE_REQUESTED ? 'change request info' : 'approval info' }}.</span>
  </div>

  <div class="flex justify-end">
    <button mat-flat-button color="primary" (click)="closeDialog()">OK</button>
  </div>

</mat-card>
