import {Component} from '@angular/core';

@Component({
	selector: 'app-main-toolbar',
	templateUrl: './main-toolbar.component.html',
	styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent {
	constructor() {}

}
