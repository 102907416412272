<form #ngForm="ngForm" (ngSubmit)="sendRequest()">
<mat-card class="flex flex-col p-6" [formGroup]="changeRequestForm">
  <mat-card-title>Change Request Info</mat-card-title>

  <mat-form-field class="mt-2" subscriptSizing="dynamic">
    <mat-label>Select the reason for the change: </mat-label>
    <mat-select
      formControlName="changeRequestCode"
      (selectionChange)="setChangeRequestReason($event.value)">
      <mat-option *ngFor="let changeRequestCategory of codes" [value]="changeRequestCategory">
        {{changeRequestCategory.codeDescription}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <span class="text-red mt-1 mb-2 ml-2" *ngIf="ngForm.submitted && changeRequestForm.get('changeRequestCode')?.invalid">This is a required field</span>
  <span *ngIf="!ngForm.submitted || !changeRequestForm.get('changeRequestCode')?.invalid" class="my-2"></span>

  <mat-form-field subscriptSizing="dynamic">
    <mat-label> Describe the change: </mat-label>
    <textarea matInput formControlName="changeRequestDescription"></textarea>
    <mat-error *ngIf="ngForm.submitted &&
    changeRequestForm.get('changeRequestDescription')?.invalid">
      This is a required field
    </mat-error>
  </mat-form-field>

  <span class="text-red mt-1 mb-2 ml-2" *ngIf="ngForm.submitted && changeRequestForm.get('changeRequestDescription')?.invalid">This is a required field</span>
  <span *ngIf="!ngForm.submitted || !changeRequestForm.get('changeRequestDescription')?.invalid" class="my-2"></span>

  <button mat-button color="primary" *ngIf="!showFileUpload" (click)="showFileUpload = true">ATTACH UPDATED FILES</button>

  <div *ngIf="showFileUpload">
    <div class="flex justify-center">
      <span>Please upload any files related to the requested changes.</span>
    </div>

    <ngx-dropzone (change)="onFilesAdded($event)"
                  [expandable]="true"
                  [ngStyle]="{
						minHeight: '200px',
						height: 'fit-content',
						overflow: 'auto',
						alignItems: this.files.length}">
      <ngx-dropzone-label>
        <div class="bg-violet-300 p-2 circle">
          <mat-icon>upload_file</mat-icon>
        </div>
        <span class="text-violet-600 underline">Click to upload&nbsp;</span><span>or drag and drop.</span>
      </ngx-dropzone-label>
      <!--File Previews-->
      <ngx-dropzone-preview
        *ngFor="let f of files"
        [file]="f"
        [removable]="true"
        (removed)="onRemove(f)"
        (click)="stopPropagation($event)"
        class="cursor-default border border-black relative"
      >
        <ngx-dropzone-label class="text-black"
        >{{ f.name }} ({{ f.type }})
          <span class="absolute bottom-0 right-1 italic text-red">New</span>
        </ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone></div>


  <div class="flex justify-end space-x-2 mt-4">
    <button mat-stroked-button type="button" (click)="cancelChangeRequestEvent.emit(true)">CANCEL</button>
    <button mat-flat-button color="primary" type="submit">SUBMIT CHANGE REQUEST</button>
  </div>

</mat-card>
</form>

<div id="spinner-container" *ngIf="isLoading" class="flex flex-col space-y-2">
    <span class="text-2xl">Please stay on this page while we upload your files</span>
    <span class="text-2xl mb-2">It could take several minutes for large files</span>
    <div class="mt-2">
        <mat-spinner class="scale-125"></mat-spinner>
    </div>

</div>

