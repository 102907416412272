import {Component, Input, OnInit} from '@angular/core';
import {QuoteInfo} from "../../core/models/quote-info.interface";

@Component({
  selector: 'app-approval-info',
  templateUrl: './approval-info.component.html',
  styleUrls: ['./approval-info.component.scss']
})
export class ApprovalInfoComponent implements OnInit {
  @Input() quoteInfo: QuoteInfo = {};

  ngOnInit(): void {
  }
}
