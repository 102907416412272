import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {HttpClientModule} from "@angular/common/http";
import {ShipmentService} from "./core/services/shipment.service";
import {BidsModule} from "./bids/bids.module";
import {AuthInterceptorProviders} from './core/interceptors/auth.interceptor';
import { QuoteComponent } from './quote/quote.component';
import {QuoteService} from './core/services/quote.service';
import {PdfViewerModule} from "ng2-pdf-viewer";
import {MatButtonModule} from "@angular/material/button";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatNativeDateModule} from "@angular/material/core";
import {BookingsModule} from "./bookings/bookings.module";
import { ApproveDialogComponent } from './quote/dialogs/approve-dialog/approve-dialog.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCardModule} from "@angular/material/card";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import {MatExpansionModule} from "@angular/material/expansion";
import {NgxDropzoneModule} from "ngx-dropzone";
import {MatIconModule} from "@angular/material/icon";
import { QuoteApprovalComponent } from './quote/quote-approval/quote-approval.component';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ThankYouComponent } from './thank-you/thank-you.component';
import {MainToolbarComponent} from "./core/components/main-toolbar/main-toolbar.component";
import {FooterComponent} from "./core/components/footer/footer.component";
import {SharedModule} from "./shared/shared.module";
import { ApprovalInfoComponent } from './quote/approval-info/approval-info.component';
import { ChangeRequestInfoComponent } from './quote/change-request-info/change-request-info.component';
import { PdfContainerComponent } from './quote/pdf-container/pdf-container.component';
import { ConfirmationComponent } from './quote/confirmation/confirmation.component';
import { ErrorDialogComponent } from './quote/dialogs/error-dialog/error-dialog.component';
import { ChangeRequestComponent } from './quote/change-request/change-request.component';
import { RelatedDocumentsComponent } from './quote/related-documents/related-documents.component';
import {MatListModule} from "@angular/material/list";
import {InvalidLinkComponent} from './invalid-link/invalid-link.component';


@NgModule({
  declarations: [
    AppComponent,
    ThankYouComponent,
    AppComponent,
    QuoteComponent,
    ApproveDialogComponent,
    QuoteApprovalComponent,
    MainToolbarComponent,
    FooterComponent,
    ApprovalInfoComponent,
    ChangeRequestInfoComponent,
    PdfContainerComponent,
    ConfirmationComponent,
    ErrorDialogComponent,
    ChangeRequestComponent,
    RelatedDocumentsComponent,
    InvalidLinkComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BidsModule,
    BookingsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    PdfViewerModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCardModule,
    MatDatepickerModule,
    MatSelectModule,
    MatExpansionModule,
    NgxDropzoneModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    SharedModule,
    MatListModule,
  ],
  providers: [
    ShipmentService,
    AuthInterceptorProviders,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {duration: 2500}
    },
    QuoteService,
    AuthInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
