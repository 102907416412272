<div class="flex flex-col space-y-2 justify-center h-full p-4 mat-elevation-z4 my-2 pointer-events" >
  <div class="flex justify-between ">
    <button mat-stroked-button color="primary" (click)="downloadPdf()" class="justify-self-start" type="button">DOWNLOAD PDF</button>
    <div>
      <button mat-stroked-button (click)="decreaseZoom()" type="button"><mat-icon>zoom_out</mat-icon>Zoom Out</button>
      <button mat-stroked-button (click)="increaseZoom()" type="button" class="ml-1"><mat-icon>zoom_in</mat-icon>Zoom In</button>
    </div>
  </div>
  <pdf-viewer [src]="this.pdfSrc"
              #pdfViewer1
              [rotation]="0"
              [original-size]="false"
              [show-all]="true"
              [fit-to-page]="true"
              [zoom]="zoom"
              [zoom-scale]="'page-width'"
              [stick-to-page]="false"
              [render-text]="true"
              [external-link-target]="'blank'"
              [autoresize]="true"
              [show-borders]="false"
              (page-rendered)="triggerConsole()"
              (mousemove)="onDrag($event, pdfViewer1)"
              (mousedown)="onDragStarted()"
              (mouseup)="onDragEnded()"
              (mouseleave)="onDragEnded()"
              class="self-center mt-2 cursor-grab"
              style="width: 40rem; height: 44rem; max-width: 750px;">
  </pdf-viewer>



</div>
