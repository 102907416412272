<form (ngSubmit)="approveQuote()" #ngForm="ngForm" class="overflow-x-hidden overflow-y-hidden">
  <div class="w-[100vw] h-[100vh] flex justify-center" *ngIf="(status === QuoteStatusEnum.APPROVAL_REQUESTED || status === QuoteStatusEnum.APPROVED)&& !pdfRendered">
    <mat-spinner class="mt-20"></mat-spinner>
  </div>

  <div class="flex flex-col space-y-2 h-[100%]" *ngIf="!isLoading">
    <div *ngIf="quoteInfo?.status === QuoteStatusEnum.SALES_ORDER" class="flex flex-col items-center">
      <div class="flex items-center my-18">
        <img src="/assets/mmi-logo.jpg" alt="MMI Logo" class="h-64" />
      </div>
      <span class="text-2xl mt-14 mb-2">A Sales Order Has Been Created For This Quote.</span>
      <button mat-stroked-button (click)="downloadPdf()" color="primary" class="mb-30">Download Quote PDF</button>
    </div>
    <div class="flex 2xl:flex-row flex-col place-items-center 2xl:place-items-start 2xl:justify-center w-[100vw] " *ngIf="quoteInfo?.status !== QuoteStatusEnum.SALES_ORDER">
      <app-pdf-container
        *ngIf="(status === QuoteStatusEnum.APPROVAL_REQUESTED) || (status === QuoteStatusEnum.APPROVED)"
        [pdfSrc]="pdfSrc"
        (pdfRenderedEvent)="pdfRendered = true"
      ></app-pdf-container>
      <div class="flex flex-col space-y-2 ml-2" *ngIf="status !== QuoteStatusEnum.CHANGE_REQUESTED">
        <app-related-documents
          *ngIf="quoteInfo?.relatedDocuments?.length"
          [quoteInfo]="quoteInfo!"
          [quoteId]="quoteId!"
          class="mt-2 mat-elevation-z4 w-[560px] max-h-[300px]"></app-related-documents>
        <app-approval-info
          *ngIf="status === QuoteStatusEnum.APPROVED"
          [quoteInfo]="quoteInfo!"
          class="mt-2 w-[560px] mat-elevation-z4"
        ></app-approval-info>
        <app-change-request
          *ngIf="changeRequestMode"
          [quoteInfo]="quoteInfo!"
          [quoteId]="quoteId!"
          (cancelChangeRequestEvent)="changeRequestMode = false"
          class="mat-elevation-z4 mt-2 w-[560px]">
        </app-change-request>
        <app-quote-approval
          *ngIf="status === QuoteStatusEnum.APPROVAL_REQUESTED && !changeRequestMode"
          [dateOptions]="dateOptions"
          [approvalForm]="approvalForm"
          [ngForm]="ngForm"
          (fileChangeEmitter)="fileArrayChanged($event)"
          [quoteInfo]="quoteInfo"
          class="mt-2 mat-elevation-z4 h-1/2 w-[560px]"></app-quote-approval>
        <div class="flex justify-end mt-4" *ngIf="status === QuoteStatusEnum.APPROVAL_REQUESTED || status === QuoteStatusEnum.APPROVED">
          <div class="flex space-x-1">
            <button mat-stroked-button *ngIf="status === QuoteStatusEnum.APPROVAL_REQUESTED && !changeRequestMode" (click)="changeRequestMode = true">REQUEST CHANGES</button>
            <button type="submit" mat-flat-button color="primary" *ngIf="status === QuoteStatusEnum.APPROVAL_REQUESTED && !changeRequestMode">APPROVE</button>
          </div>
        </div>
      </div>

      <app-change-request-info
        *ngIf="status === QuoteStatusEnum.CHANGE_REQUESTED"
         [quoteInfo]="quoteInfo!"
      ></app-change-request-info>
    </div>

  </div>

</form>





