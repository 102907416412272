<mat-card class="p-6">
  <mat-card-title>Final Drafting Documents</mat-card-title>
  <mat-card-subtitle class="ml-4 mb-2">Here are the final drafting documents associated with this quote;</mat-card-subtitle>
  <mat-card-content class="max-h-[150px] overflow-y-scroll mt-2">
    <mat-list class="mt-2">

      <mat-list-item class="ml-8 hover:bg-gray-100 hover:rounded max-w-sm" *ngFor="let document of quoteInfo.relatedDocuments">
        <mat-icon matListItemIcon class="mt-1">insert_drive_file</mat-icon>
        <div matListItemTitle
             class="cursor-pointer underline text-blue-500 text-lg"
             (click)="downloadFile($event, document)">{{document.documentName}}</div>
        <div matListItemLine>{{document.type}}</div>
      </mat-list-item>

    </mat-list>

  </mat-card-content>

</mat-card>
