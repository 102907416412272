import {Component, Input} from '@angular/core';
import {QuoteInfo} from "../../core/models/quote-info.interface";
import {QuoteService} from "../../core/services/quote.service";
import {ProjectDocument} from "../../core/models/project-document.interface";

@Component({
  selector: 'app-related-documents',
  templateUrl: './related-documents.component.html',
  styleUrls: ['./related-documents.component.scss']
})
export class RelatedDocumentsComponent {
  @Input() quoteInfo: QuoteInfo = {};
  @Input() quoteId: number = 0;

  constructor(private quoteService: QuoteService) {

  }
  downloadFile(event: Event, projectDocument: ProjectDocument): void {
    event.stopPropagation();
    this.quoteService.findOneQuoteDocument(this.quoteId, projectDocument).subscribe((response): void => {
      const a: HTMLAnchorElement = document.createElement('a');
      a.style.display = 'none';
      a.href = response;
      a.download = projectDocument.documentName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    });
  }
}
