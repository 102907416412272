import {NgModule} from '@angular/core';
import {FileUploadComponent} from "./components/file-upload/file-upload.component";
import {NgxDropzoneModule} from "ngx-dropzone";
import {MatIconModule} from "@angular/material/icon";
import {NgForOf} from "@angular/common";
import {NotFoundComponent} from './components/not-found/not-found..component';

@NgModule({
	declarations: [
    FileUploadComponent,
    NotFoundComponent
	],
	exports: [
    FileUploadComponent
	],
  imports: [
    NgxDropzoneModule,
    MatIconModule,
    NgForOf
  ],
	providers: []
})
export class SharedModule {}
