<mat-card class="flex flex-col p-6 h-full">

  <mat-card-title> Approval Confirmation </mat-card-title>

  <span> Are you sure you want to approve this quote? </span>

  <div class="flex flex-row mt-4 space-x-2 self-end justify-self-end">
    <button mat-flat-button (click)="cancel()"> CANCEL </button>
    <button mat-flat-button color="primary" (click)="onConfirmation()"> APPROVE </button>
  </div>

</mat-card>


