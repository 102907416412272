import {Injectable} from '@angular/core';
import {Code} from '../models/shipping-code.model';

@Injectable({
	providedIn: 'root'
})
export class ShippingCodeService {
	constructor() {}

	codeMaster: IMasterData = {
		ETYPE: [
			{value: '', description: 'Any', data: 'assistant'},
			{value: 'FTL', description: 'Truck Load', data: 'local_shipping'},
			{value: 'LTL', description: 'LTL Shipment', data: 'inventory_2'}
		],
		STERM: [
			{value: 'PRE', description: 'Prepaid'},
			{value: '3RD', description: '3rd Party'}
		],
		SDIR: [
			{value: 'OUT', description: 'Outbound'},
			{value: 'IN', description: 'Inbound'}
		],
		HUTYPE: [{value: 'PALLET', description: 'Pallet', default: 1}],
    TIME: [
      { value: '00:00:00', description: '12:00 AM'},
      { value: '00:30:00', description: '12:30 AM'},
      { value: '01:00:00', description: '1:00 AM'},
      { value: '01:30:00', description: '1:30 AM'},
      { value: '02:00:00', description: '2:00 AM'},
      { value: '02:30:00', description: '2:30 AM'},
      { value: '03:00:00', description: '3:00 AM'},
      { value: '03:30:00', description: '3:30 AM'},
      { value: '04:00:00', description: '4:00 AM'},
      { value: '04:30:00', description: '4:30 AM'},
      { value: '05:00:00', description: '5:00 AM'},
      { value: '05:30:00', description: '5:30 AM'},
      { value: '06:00:00', description: '6:00 AM'},
      { value: '06:30:00', description: '6:30 AM'},
      { value: '07:00:00', description: '7:00 AM'},
      { value: '07:30:00', description: '7:30 AM'},
      { value: '08:00:00', description: '8:00 AM', default: 1},
      { value: '08:30:00', description: '8:30 AM'},
      { value: '09:00:00', description: '9:00 AM'},
      { value: '09:30:00', description: '9:30 AM'},
      { value: '10:00:00', description: '10:00 AM'},
      { value: '10:30:00', description: '10:30 AM'},
      { value: '11:00:00', description: '11:00 AM'},
      { value: '11:30:00', description: '11:30 AM'},
      { value: '12:00:00', description: '12:00 PM'},
      { value: '12:30:00', description: '12:30 PM'},
      { value: '13:00:00', description: '1:00 PM'},
      { value: '13:30:00', description: '1:30 PM'},
      { value: '14:00:00', description: '2:00 PM'},
      { value: '14:30:00', description: '2:30 PM'},
      { value: '15:00:00', description: '3:00 PM'},
      { value: '15:30:00', description: '3:30 PM'},
      { value: '16:00:00', description: '4:00 PM'},
      { value: '16:30:00', description: '4:30 PM'},
      { value: '17:00:00', description: '5:00 PM'},
      { value: '17:30:00', description: '5:30 PM'},
      { value: '18:00:00', description: '6:00 PM'},
      { value: '18:30:00', description: '6:30 PM'},
      { value: '19:00:00', description: '7:00 PM'},
      { value: '19:30:00', description: '7:30 PM'},
      { value: '20:00:00', description: '8:00 PM'},
      { value: '20:30:00', description: '8:30 PM'},
      { value: '21:00:00', description: '9:00 PM'},
      { value: '21:30:00', description: '9:30 PM'},
      { value: '22:00:00', description: '10:00 PM'},
      { value: '22:30:00', description: '10:30 PM'},
      { value: '23:00:00', description: '11:00 PM'},
      { value: '23:30:00', description: '11:30 PM'},
    ],
    TIMEZONE: [
      { value: 'ET', description: 'ET', default: 1},
      { value: 'CT', description: 'CT', default: 1},
      { value: 'MT', description: 'MT', default: 1},
      { value: 'PT', description: 'PT', default: 1},
      { value: 'AK', description: 'AK', default: 1},
      { value: 'HAST', description: 'HAST', default: 1}

    ]
	};

	getCodes(type: string): Code[] {
		return this.codeMaster[type as keyof IMasterData];
	}

	getDefault(type: string) {
		return this.codeMaster[type as keyof IMasterData].find((code) => (code.default = 1))?.value;
	}
}

interface ICode {
	id?: number;
	type?: string;
	value?: string;
	description?: string;
	data?: string;
	default?: number;
}

interface IMasterData {
	ETYPE: ICode[];
	STERM: ICode[];
	SDIR: ICode[];
	HUTYPE: ICode[];
  TIME: ICode[];
  TIMEZONE: ICode[];
}
