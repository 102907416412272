

<div class="flex flex-col items-center h-full">
  <div class="mt-32 text-3xl">THANK YOU!</div>
  <div class="flex items-center">
    <mat-icon class="check-icon"> check</mat-icon>
  </div>
  <p class="text-lg w-2/5 text-center">Thanks a bunch. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you.</p>
</div>

