import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {Contact} from "../../core/models/contact.interface";
import {Code} from "../../core/models/code.interface";
import {CodeService} from "../../core/services/code.service";
import {CodeTypeEnum} from "../../core/enums/code-type.enum";
import {MatSnackBar} from "@angular/material/snack-bar";
import {QuoteService} from "../../core/services/quote.service";
import {ChangeRequestInfo} from "../../core/models/change-request-info.interface";
import {SnackbarActionEnum} from "../../core/enums/snackbar-action.enum";
import {ErrorDialogComponent} from "../dialogs/error-dialog/error-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {QuoteInfo} from "../../core/models/quote-info.interface";
import {QuoteDocument} from "../../core/models/quote-document.interface";
import {Router} from "@angular/router";

@Component({
  selector: 'app-change-request',
  templateUrl: './change-request.component.html',
  styleUrls: ['./change-request.component.scss']
})
export class ChangeRequestComponent implements OnInit {
  changeRequestForm = this.fb.group({
    changeRequestReason: ['', Validators.required],
    changeRequestDescription: ['', Validators.required],
    changeRequestCode: [null, Validators.required],
    changeRequestEmail: [''],
    changeRequestName: [''],
  });


  @Input() quoteInfo: QuoteInfo = {};
  @Input() quoteId: number = 0;
  @Output() cancelChangeRequestEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  codes: Code[] = [];
  files: File[] = [];
  showFileUpload: boolean = false;
  quoteDocuments: QuoteDocument[] = []
  contacts: Contact[] = [];
  isLoading: boolean = false;
  loadingValue: number = 0;


  constructor(private fb: FormBuilder,
              private codeService: CodeService,
              private snackbar: MatSnackBar,
              private quoteService: QuoteService,
              private dialog: MatDialog,
              private router: Router) {
  }

  ngOnInit(): void {
    this.contacts = this.quoteInfo?.contacts!;

    this.codeService.findByType(CodeTypeEnum.CHANGE_REQUEST_REASONS).subscribe({
      next: (response: Code[]) => this.codes = response,
      error: (err): void => console.error(err)
    });
  }

  sendRequest(): void {

    if (this.changeRequestForm.invalid) {
      this.snackbar.open('Please fill out all required fields before submitting your change request', SnackbarActionEnum.ERROR);
      return;
    }


    let changeRequestInfo: ChangeRequestInfo = {};
    changeRequestInfo.changeRequestCode = this.changeRequestForm.get('changeRequestCode')?.value!;
    changeRequestInfo.changeRequestReason = this.changeRequestForm.get('changeRequestReason')?.value!;
    changeRequestInfo.changeRequestDescription = this.changeRequestForm.get('changeRequestDescription')?.value!;
    changeRequestInfo.changeRequestEmail = this.quoteInfo.approverEmail;
    changeRequestInfo.changeRequestName = this.quoteInfo.approverName;
    changeRequestInfo.changeRequestDate = new Date().toISOString().split('T')[0];
    changeRequestInfo.quoteDocuments = this.quoteDocuments;

    let formData: FormData = new FormData();
    for (let i: number = 0; i < this.files.length; i++) {
      formData.append('files', this.files[i], this.files[i].name);
    }
    formData.append('body', JSON.stringify(changeRequestInfo));
    this.isLoading = true;
    this.quoteService.requestChanges(this.quoteId, formData).subscribe({
      next: (response: QuoteInfo): void => {
          this.isLoading = false;
          if (!response.conflictingUpdate) {
            this.router.navigate(['/confirmation']).then();
          } else {
            const errorDialogRef: MatDialogRef<ErrorDialogComponent> = this.dialog.open(ErrorDialogComponent, {
                data: {
                  status: response.status,
                  quoteNo: response.quoteNo
                }
          });

          errorDialogRef.afterClosed().subscribe({
            next: () => window.location.reload()
          });
        }
      },
      error: (err): void => {
        console.error(err);
        this.snackbar.open('Failed to submit change request, please try again', SnackbarActionEnum.ERROR)
      }
    });
  }

  setEmail(contact: Contact): void {
    this.changeRequestForm.get('changeRequestEmail')?.setValue(contact.email!);
    this.changeRequestForm.get('changeRequestName')?.setValue(contact.name!)
  }

  setChangeRequestReason(reasonCode: Code): void {
    this.changeRequestForm.get('changeRequestReason')?.setValue(reasonCode.codeDescription!)
  }

  onFilesAdded(event: any): void {
    let fileNameLengthExceeded: boolean = false;
    event.addedFiles.forEach((file: File) => {
      if (file.name.length > 100) {
        fileNameLengthExceeded = true;
      }
    });

    if (fileNameLengthExceeded) {
      this.snackbar.open('Length of file name cannot exceed 100 characters, including extension.', SnackbarActionEnum.ERROR, {duration:4800});
      return;
    }

    const fileArray: File[] = [];
    fileArray.push(...event.addedFiles);
    fileArray.forEach((each: File, i: number): void => {
        this.files.push(fileArray[i]);
        this.quoteDocuments.push({
            documentName: fileArray[i].name,
            type: fileArray[i].type,
            quoteId: +this.quoteId,
        });
    });
  }

  onRemove(file: any): void {
    this.files.splice(this.files.indexOf(file), 1);
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }


}
