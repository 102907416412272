import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Shipment} from "../models/shipment.interface";

@Injectable({
	providedIn: 'root'
})
export class ShipmentService {
	baseUrl: string = `${environment.url}/shipments`;
	constructor(private http: HttpClient) {}

  findOne(id: number): Observable<Shipment> {
    return this.http.get<Shipment>(`${this.baseUrl}/${id}`);
  }
}
